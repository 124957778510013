/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url('https://fonts.cdnfonts.com/css/bluntype');


.ion-card-content {

  --ion-background-color: white;
}

.new-background-color {
  --background: #364F6B;
}

a {
  color: black;
  text-decoration: none;
  /* no underline */
}

.ion-card-header {
  --background: #364F6B;
}

figure>img {
  height: auto;
}

.content-page {
  --ion-background-color: whitesmoke;
}

div[scrollx=true] {


  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .scroll-item {
    flex: 0 0 auto;
  }
}

.col-step {
  color: gray;
  text-align: center;
}

.step-none {
  background-color: #e0e0e0;
  color: gray;
  font-size: 24px;
  font-weight: bolder;
  text-align: center;
  vertical-align: middle;
  padding: 10px;
  border-radius: 90px;
}

.step {
  background-color: #FCC139;
  color: black;
  font-size: 24px;
  font-weight: bolder;
  text-align: center;
  vertical-align: middle;
  padding: 10px;
  border-radius: 60px;
}

.col-step>p {
  font-size: 14px;
}

.active {
  background-color: #774360 !important;
  color: white;
}
.logo-center {
  max-height: 40px; 
  height: 100%; 
   
  display:block;
}
@supports (-webkit-touch-callout: none) {
  
}

@supports not (-webkit-touch-callout: none) {
  /* CSS for other than iOS devices */ 
}